<template>
  <div class='home' ref='scrollbox'>
<!--    <homeBanner></homeBanner>-->
    <div class='banner_1'>
      <div class='swiper_content'>
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="false"
                      :options="playerOptions">
        </video-player>
        <!--        <div class="swiper-container">-->
<!--        <swiper :options='swiperOptions1'>-->
<!--          <swiper-slide v-for='(item,index) in logoList' :key='index'>-->
<!--            <a :href='item.link_url'><img :src='item.img'/></a>-->
<!--          </swiper-slide>-->
<!--          &lt;!&ndash; 如果需要分页器 slot插槽 会自动往里面渲染分页器&ndash;&gt;-->
<!--          &lt;!&ndash;                      <div class="swiper-pagination" slot="pagination"></div>&ndash;&gt;-->
<!--          &lt;!&ndash; 如果需要导航按钮 &ndash;&gt;-->
<!--          &lt;!&ndash;          <div class='swiper-button-prev prev1' slot='button-prev'></div>&ndash;&gt;-->
<!--          &lt;!&ndash;          <div class='swiper-button-next next1' slot='button-next'></div>&ndash;&gt;-->
<!--        </swiper>-->
        <!--      </div>-->
      </div>
    </div>
    <div class='banner_data'>
      <div class='banner_data_wrapper'>
        <div class='banner_data_item'>
          <div class='banner_data_item_num'>{{dataJson && dataJson.fans_num ? dataJson.fans_num : 0}}</div>
          <div class='banner_data_item_name'>{{$t('LIVE.NumberOfFans')}}</div>
        </div>
        <div class='banner-line'></div>
        <div class='banner_data_item'>
          <div class='banner_data_item_num'>{{dataJson && dataJson.service_artists ? dataJson.service_artists : 0}}</div>
          <div class='banner_data_item_name'>{{$t('LIVE.ServiceArtist')}}</div>
        </div>
        <div class='banner-line'></div>
        <div class='banner_data_item'>
          <div class='banner_data_item_num'>{{dataJson && dataJson.sign_artists ? dataJson.sign_artists : 0}}</div>
          <div class='banner_data_item_name'>{{$t('LIVE.signedArtist')}}</div>
        </div>
        <div class='banner-line'></div>
        <div class='banner_data_item'>
          <div class='banner_data_item_num'>{{dataJson && dataJson.cooperative_brand ? dataJson.cooperative_brand : 0}}</div>
          <div class='banner_data_item_name'>{{$t('LIVE.cooperativeBrand')}}</div>
        </div>
      </div>
    </div>
    <div class='banner_introduction'>
      <div class='box_introduction' style="position: relative">
        <div class='box_introduction_title'>
          <div class='introduction_title'>{{$t('LIVE.BrightChineseStars')}}</div>
          <img src='../assets/home/logo-img.png'/>
        </div>
        <div class='introduction_content'>
          <div class='paragraphs'>
            <div>{{dataJson.hx_desc ? dataJson.hx_desc : ''}}</div>
          </div>
        </div>
        <div class='know_us' style="margin-left: auto;margin-right: auto">

          <el-button class='know_more' @click="gotoHxxd">{{$t('LIVE.LearnHuaxing')}}>>></el-button>
        </div>
      </div>
    </div>
    <div class='banner_live'>
      <div class='position-center-s'>
<!--        <div class='banner_live_letter'>直播</div>-->
        <div class='live_introduction'>
<!--          <div class='live_introduction_dot'>-->
<!--            <img src='../assets/home/dot.png'/>-->
<!--          </div>-->
          <div>
            <div class='live_introduction_title'>
<!--              <div class='number-show-t'>01</div>-->
              <div class='live_introduction_title_name'>{{NewsJson ? NewsJson.title : ''}}</div>

              <div class='live_introduction_title_logo'>
                <img :src='NewsJson ? NewsJson.icon : ""'/>
              </div>
            </div>
            <el-divider></el-divider>
            <div class='live_introduction_content'>
<!--              {{NewsJson ? NewsJson.desc : ''}}-->
              {{showBtn && !hideButton ? sliceStr : NewsJson.desc }}<span class="btnWord" @click="showBtn=!showBtn" v-if='!hideButton'>{{showBtn?"展开":"收起"}}</span>
            </div>
          </div>
<!--          <div class='live_introduction_bg'>-->
<!--            <img src='../assets/home/HuaxingliveBroadcast.png'/>-->
<!--          </div>-->
          <div class='know_us' style='text-align: left' @click="gotoMore">
            <el-button class='know_more'>{{$t('LIVE.LearnMore')}}>>></el-button>
          </div>
        </div>
        <div class='live_swiper'>
          <div class='live_swiper_img' v-if='News_list.length==1'>
            <a :href='News_list[0].link_url&&News_list[0].link_url.indexOf("http") >= 0  ? News_list[0].link_url : null' target='_blank'><img :src='News_list[0].img'></a>
          </div>
          <!--        <div class="swiper-container">-->
          <div v-else>
            <swiper :options='swiperOptions'>
              <swiper-slide v-for='(item,index) in News_list' :key='index'>
                <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank'><img :src='item.img'/></a>
              </swiper-slide>
              <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
              <!--            <div class="swiper-pagination" slot="pagination"></div>-->
              <!-- 如果需要导航按钮 -->
            </swiper>
            <div class='swiper-button-prev prev1' slot='button-prev'></div>
            <div class='swiper-button-next next1' slot='button-next'></div>
          </div>
          <!--      </div>-->
        </div>
      </div>
    </div>
    <div class='banner_live banner_MCN'>
      <div class='position-center-s'>
        <div class='mcn_pic' v-if='News_list2.length==1'>
          <a :href='News_list2[0].link_url&&News_list2[0].link_url.indexOf("http") >= 0  ? News_list2[0].link_url : null' target='_blank'><img :src='News_list2[0].img'></a>
        </div>
        <div v-else class='mcn_swiper'>
          <swiper :options='swiperOptionsMcn'>
            <swiper-slide v-for='(item,index) in News_list2' :key='index'>
              <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank'><img :src='item.img'/></a>
            </swiper-slide>
            <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
            <!--            <div class="swiper-pagination" slot="pagination"></div>-->
            <!-- 如果需要导航按钮 -->
          </swiper>
          <div class='swiper-button-prev prevMcn' slot='button-prev'></div>
          <div class='swiper-button-next nextMcn' slot='button-next'></div>
        </div>
        <div class='live_introduction' style='margin-left: 40px;margin-right: -40px'>
          <div>
            <div class='live_introduction_title'>
<!--              <div class='number-show-t'>02</div>-->
              <div class='live_introduction_title_name'>{{NewsJson2 ? NewsJson2.title : 'MCN'}}</div>
              <div class='live_introduction_title_logo'>
                <img :src='NewsJson2 ? NewsJson2.icon : ""'/>
              </div>
            </div>
            <el-divider></el-divider>
            <div class='live_introduction_content'>
<!--              {{NewsJson2 ? NewsJson2.desc : ''}}-->
              {{showBtn2 && !hideButton ? sliceStr2 : NewsJson2.desc }}<span class="btnWord" @click="showBtn2=!showBtn2" v-if='!hideButton'>{{showBtn2?"展开":"收起"}}</span>
            </div>
<!--            <div class='MCN_introduction_bg'>-->
<!--              <img src='../assets/home/HuaxingMCN.png'/>-->
<!--            </div>-->
          </div>
          <div class='know_us' style='text-align: left' @click="gotoMCN">
            <el-button class='know_more'>{{$t('LIVE.LearnMore')}}>>></el-button>
          </div>
          <!--          <div class='live_introduction_dot MCN_introduction_dot'>-->
          <!--            <img src='../assets/home/dot.png'/>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
<!--    <div class='banner_live' style='padding-bottom: 0px'>-->
<!--      <div class='position-center-s'>-->
<!--        <div class='live_introduction'>-->
<!--          <div>-->
<!--            <div class='live_introduction_title'>-->
<!--              <div class='live_introduction_title_name'>{{NewsJson3 ? NewsJson3.title : ''}}</div>-->
<!--              <div class='live_introduction_title_logo'>-->
<!--                <img :src='NewsJson3 ? NewsJson3.icon :""' />-->
<!--              </div>-->
<!--            </div>-->
<!--            <el-divider></el-divider>-->
<!--            <div class='live_introduction_content'>-->
<!--              {{showBtn3 && !hideButton ? sliceStr3 : NewsJson3.desc }}<span class="btnWord" @click="showBtn3=!showBtn3" v-if='!hideButton'>{{showBtn3?"展开":"收起"}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class='know_us' style='text-align: left' @click="gotoShop">-->
<!--            <el-button class='know_more'>{{$t('LIVE.LearnMore')}}>>></el-button>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class='live_swiper'>-->
<!--          <div class='live_swiper_shop' v-if='News_list3.length==1'>-->
<!--            <a :href='News_list3[0].link_url&&News_list3[0].link_url.indexOf("http") >= 0  ? News_list3[0].link_url : null' target='_blank'><img :src='News_list3[0].img' /></a>-->
<!--          </div>-->
<!--          &lt;!&ndash;        <div class="swiper-container">&ndash;&gt;-->
<!--         <div v-else>-->
<!--           <swiper :options='swiperOptions2'>-->
<!--             <swiper-slide v-for='(item,index) in News_list3' :key='index'>-->
<!--               <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank'><img :src='item.img'/></a>-->
<!--             </swiper-slide>-->
<!--             &lt;!&ndash; 如果需要分页器 slot插槽 会自动往里面渲染分页器&ndash;&gt;-->
<!--             &lt;!&ndash;            <div class="swiper-pagination" slot="pagination"></div>&ndash;&gt;-->
<!--             &lt;!&ndash; 如果需要导航按钮 &ndash;&gt;-->
<!--           </swiper>-->
<!--           <div class='swiper-button-prev prev' slot='button-prev'></div>-->
<!--           <div class='swiper-button-next next' slot='button-next'></div>-->
<!--         </div>-->
<!--          &lt;!&ndash;      </div>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--&lt;!&ndash;      <div class='banner_live_letter'>电商</div>&ndash;&gt;-->
<!--    </div>-->

    <div class='banner_live' >
      <div class='position-center-s'>
<!--        <div class='banner_live_letter'>广告</div>-->
        <div class='live_introduction'>
          <div>
            <div class='live_introduction_title'>
<!--              <div class='number-show-t'>05</div>-->
              <div class='live_introduction_title_name'>{{NewsJson5 ? NewsJson5.title : ''}}</div>
              <div class='live_introduction_title_logo'>
                <img :src='NewsJson5 ? NewsJson5.icon : ""'/>
              </div>
            </div>
            <el-divider></el-divider>
            <div class='live_introduction_content' ref='NewsJson5'>
              {{ showBtn5 && !hideButton ? sliceStr5 : NewsJson5.desc}}<span class="btnWord" @click="showBtn5=!showBtn5" v-if='!hideButton'>{{showBtn5 ?"展开": "收起"}}</span>
            </div>
<!--            <div class='live_introduction_bg ad_logo'>-->
<!--              <img src='../assets/home/HuaxingBrandService.png'/>-->
<!--            </div>-->
          </div>
          <div class='know_us' style='text-align: left' @click="gotoAd">
            <el-button class='know_more'>{{$t('LIVE.LearnMore')}}>>></el-button>
          </div>
<!--          <div class='live_introduction_dot ad_introduction_dot' style='margin-top: 80px'>-->
<!--            <img src='../assets/home/dot.png'/>-->
<!--          </div>-->
        </div>
        <div class='live_swiper'>
          <div class='live_swiper_img' v-if='News_list5.length==1'>
            <a :href='News_list5[0].link_url&&News_list5[0].link_url.indexOf("http") >= 0  ? News_list5[0].link_url : null' target='_blank'><img :src='News_list5[0].img'></a>
          </div>
          <div v-else>
            <swiper :options='swiperOptions5'>
              <swiper-slide v-for='(item,index) in News_list5' :key='index'>
                <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank'><img :src='item.img'/></a>
              </swiper-slide>
              <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
              <!--            <div class="swiper-pagination" slot="pagination"></div>-->
              <!-- 如果需要导航按钮 -->
            </swiper>
            <div class='swiper-button-prev prev5' slot='button-prev'></div>
            <div class='swiper-button-next next5' slot='button-next'></div>
          </div>
        </div>
      </div>
    </div>
    <div class='banner_live game_banner'>
      <div class='position-center-s'>
        <div class='game_swiper_img_wrapper' v-if='News_list4.length==1'>
          <a :href='News_list4[0].link_url&&News_list4[0].link_url.indexOf("http") >= 0  ? News_list4[0].link_url : null' target='_blank'><img :src='News_list4[0].img' /></a>
        </div>
        <div class='game_swiper' v-else>
          <!--        <div class="swiper-container">-->
          <swiper :options='swiperOptions3'>
            <swiper-slide v-for='(item,index) in News_list4' :key='index'>
              <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank'><img :src='item.img'/></a>
            </swiper-slide>
            <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
            <!--            <div class="swiper-pagination" slot="pagination"></div>-->
            <!-- 如果需要导航按钮 -->
          </swiper>
          <div class='swiper-button-prev prev3' slot='button-prev'></div>
          <div class='swiper-button-next next3' slot='button-next'></div>
          <!--      </div>-->
        </div>
        <div class='live_introduction'>
          <div>
            <div class='live_introduction_title'>
              <!--              <div class='number-show-t'>04</div>-->
              <div class='live_introduction_title_name'>{{NewsJson4 ? NewsJson4.title : ''}}</div>
              <div class='live_introduction_title_logo'>
                <img :src='NewsJson4 ? NewsJson4.icon : ""'/>
              </div>
            </div>
            <el-divider></el-divider>
            <div class='live_introduction_content'>
              {{showBtn4 && !hideButton ? sliceStr4 : NewsJson4.desc }}<span class="btnWord" @click="showBtn4=!showBtn4" v-if='!hideButton'>{{showBtn4?"展开":"收起"}}</span>
            </div>
            <!--            <div class='game_introduction_bg'>-->
            <!--              <img src='../assets/home/HuaxingGame.png'/>-->
            <!--            </div>-->
          </div>
          <div class='know_us' style='text-align: left' @click="gotoGame">
            <el-button class='know_more'>{{$t('LIVE.LearnMore')}}>>></el-button>
          </div>
          <!--          <div class='live_introduction_dot MCN_introduction_dot'>-->
          <!--            <img src='../assets/home/dot.png'/>-->
          <!--          </div>-->
        </div>
        <!--        <div class='banner_live_letter game'>游戏</div>-->
      </div>
    </div>
    <div class='new'>
      <div class='header-anchor'>
        <span>{{$t('LIVE.newsStar')}}</span>
      </div>
      <div class='Semicircle_right'>
        <img src='../assets/home/Semicircle.png'/>
      </div>
      <div class='new_wrapper'>
        <div class='new_content'>
          <div class='item_new' v-for="(item,index) in newList" :key="index">
            <a :href='item.url' target='_blank'>
              <div class='imge_wrapper'>
                <img :src=item.img />
              </div>
              <div class='item_new_content'>
                <div class='item_new_title'>{{item.title}}</div>
                <div class='item_new_text' v-html="item.desc"></div>
              </div>
              <div class='new_time'>{{item.created_at}}</div>
            </a>
          </div>
        </div>
        <div class='know_us'>
          <el-button class='know_more' @click="addCurrentPage" v-if='!noData'>
            <span>{{$t('LIVE.moreNews')}}>>></span>
          </el-button>
          <el-button class='know_more_nodata' v-else disabled>
            <span>{{$t('LIVE.noMore')}} >>></span>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'

  import "video.js/dist/video-js.css";
  import '../../node_modules/echarts/map/js/china.js'
  import china from '../../node_modules/echarts/map/json/china.json'
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import { Logo ,Index_Model_list,index_List ,Home_News_list} from '@/api/banner'
  import homeBanner from '../components/homeBanner'
  export default {
    name: 'Home',
    components: {
      videoPlayer,
      homeBanner,
      Swiper,
      SwiperSlide
    },
    data() {
      return {
        logoList: [],
        dataJson:{},
        newList:[],
        total:null,
        currentPage:1,
        NewsJson:{},
        showBtn: true,
        NewsJson2:{},
        showBtn2: true,
        NewsJson3:{},
        showBtn3: true,
        NewsJson4:{},
        showBtn4: true,
        NewsJson5:{},
        showBtn5: true,
        hideButton: true,
        maxLen: 33,
        News_list:[],
        News_list2:[],
        News_list3:[],
        News_list4:[],
        News_list5:[],
        list:[],
        type: 1,
        swiperOptions1: {
          // 自动播放
          autoplay: {
            delay: 3000,
          },
          // 环路播放
          loop: true,
          // 切换效果
          effect: 'slide',
          cubeEffect: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.next1',
            prevEl: '.swiper-button-prev.prev1',
          }
        },
        swiperOptionsMcn: {
          // 自动播放
          autoplay: true,
          // 环路播放
          loop: true,
          // 切换效果
          effect: 'slide',
          cubeEffect: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.nextMcn',
            prevEl: '.swiper-button-prev.prevMcn',
          },
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true//修改swiper的父元素时，自动初始化swiper
        },
        swiperOptions2: {
          // 自动播放
          autoplay: true,
          // 环路播放
          loop: true,
          // 切换效果
          effect: 'slide',
          cubeEffect: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.next',
            prevEl: '.swiper-button-prev.prev',
          }
        },
        swiperOptions: {
          // 自动播放
          autoplay: true,
          // 环路播放
          loop: true,
          // 切换效果
          // effect: 'slide',
          // cubeEffect: {
          //   slideShadows: true,
          //   shadow: true,
          //   shadowOffset: 100,
          //   shadowScale: 0.6
          // },
          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.next1',
            prevEl: '.swiper-button-prev.prev1',
            mouseover: function() {
            }
          }
        },
        swiperOptions5: {
          // 自动播放
          autoplay: true,
          // 环路播放
          loop: true,
          // 切换效果
          effect: 'slide',
          cubeEffect: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.next5',
            prevEl: '.swiper-button-prev.prev5',
            mouseover: function() {
            }
          }
        },
        swiperOptions3: {
          // 自动播放
          autoplay: true,
          // 环路播放
          loop: true,
          // 切换效果
          effect: 'slide',
          cubeEffect: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.next3',
            prevEl: '.swiper-button-prev.prev3',
            mouseover: function() {
            }
          }
        },
        playerOptions:{},
        option: {
          tooltip: {
            show: false
          },
          geo: {
            map: 'china',
            roam: false,// 一定要关闭拖拽
            zoom: 1,
            center: [105, 100], // 调整地图位置
            label: {
              normal: {
                show: false, //关闭省份名展示
                fontSize: '14',
                color: 'rgba(0,0,0,0.7)'
              },
              emphasis: {
                show: false
              }
            },
            itemStyle: {
              normal: {
                areaColor: '#fff',
                borderColor: '#389dff',
                borderWidth: 1, //设置外层边框
                shadowBlur: 5,
                shadowOffsetY: 8,
                shadowOffsetX: 0,
                shadowColor: 'rgba(213, 71, 36, 0.1)'
              },
              emphasis: {
                areaColor: '#184cff',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 5,
                borderWidth: 0,
                shadowColor: 'rgba(213, 71, 36, 0.1)'
              }
            }
          },
          series: [
            {
              type: 'map',
              map: 'china',
              roam: false,
              zoom: 1.23,
              center: [105, 36],
              borderColor: '#bdbdbd',
              // geoIndex: 1,
              // aspectScale: 0.75, //长宽比
              showLegendSymbol: false, // 存在legend时显示
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false,
                  textStyle: {
                    color: '#fff'
                  }
                }
              },
              itemStyle: {
                normal: {
                  areaColor: '#fff5f3',
                  borderColor: '#bdbdbd',
                  borderWidth: 0.5
                },
                emphasis: {
                  areaColor: '#fdcabd',
                  shadowOffsetX: 0,
                  shadowOffsetY: 0,
                  shadowBlur: 5,
                  borderWidth: 0,
                  shadowColor: 'rgba(189, 189, 189, 0.5)'
                }
              }
            }
          ]
        },
        noData: false
      }
    },
    computed: {
      sliceStr() {
        if (this.NewsJson.desc != null) {
          return this.NewsJson.desc.substring(0, this.maxLen) + "...";
        }
        return '';
      },
      sliceStr2() {
        if (this.NewsJson2.desc != null) {
          return this.NewsJson2.desc.substring(0, this.maxLen) + "...";
        }
        return '';
      },
      sliceStr3() {
        if (this.NewsJson3.desc != null) {
          return this.NewsJson3.desc.substring(0, this.maxLen) + "...";
        }
        return '';
      },
      sliceStr4() {
        if (this.NewsJson4.desc != null) {
          return this.NewsJson4.desc.substring(0, this.maxLen) + "...";
        }
        return '';
      },
      sliceStr5() {
        if (this.NewsJson5.desc != null) {
          return this.NewsJson5.desc.substring(0, this.maxLen) + "...";
        }
        return '';
      }
    },
    watch: {
    },
    mounted() {

      // let self = this
      //调用 <audio> 元素提供的方法 play()
      this.get_Logo()
      this.get_index_List()
      this.get_Home_News_list()
      this.get_Index_Model_list(1)
      this.get_Index_Model_list(2)
      this.get_Index_Model_list(3)
      this.get_Index_Model_list(4)
      this.get_Index_Model_list(5)
      // this.getMapChart()
      if (this._isMobile()) {
        this.hideButton = false
      } else {
        this.hideButton = true
      }
      let  language=localStorage.getItem('locale')
    },
    methods: {
      _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      },
      newDetail: function(id) {
        this.$router.push({
          path: '/newsDetails',
          query: {id: id}
        })
      },
      formatDate(item_date) {
        let date = new Date(parseInt(item_date) * 1000);
        let YY = date.getFullYear() + "-";
        let MM =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        return YY + MM + DD ;
      },
      get_Index_Model_list:function(index){
        let data={
          type:index
        }
        Index_Model_list(data).then(resp=>{
          if(resp.status==200){
            if(index==1){
              this.NewsJson=resp.data.info
              this.News_list=resp.data.img
            }else if(index==2){
              this.NewsJson2=resp.data.info
              this.News_list2=resp.data.img
            }else if(index==3){
              this.NewsJson3=resp.data.info
              this.News_list3=resp.data.img
            }else if(index==4){
              this.NewsJson4=resp.data.info
              this.News_list4=resp.data.img
            }else if(index==5){
              // console.log('resp.data---',resp.data)
              this.NewsJson5=resp.data.info
              this.News_list5=resp.data.img
            }
            let  language=localStorage.getItem('locale')
          }
        }).catch(error=>{

        })
      },
      gotoHxxd:function(){
        this.$router.push('/aboutUs')
      },
      gotoMore:function(){
        this.$router.push('/live')
      },
      gotoMCN:function(){
        this.$router.push('/MCN')
      },
      gotoShop:function(){
        this.$router.push('/shop')
      },
      gotoGame:function(){
        this.$router.push('/game')
      },
      gotoAd:function(){
        this.$router.push('/Brand')
      },
      get_Logo: function() {
        Logo(1).then(resp => {
          if (resp.status == 200) {
            // console.log('-------------------------++96+',resp.data)
            this.$set(this, 'logoList', resp.data)
            let srcData=resp.data[0].img
            let option= {
              playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                autoplay: true, //如果true,浏览器准备好时开始回放。
                muted: true, // 默认情况下将会消除任何音频。
                loop: true, // 导致视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "4:3", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                {

                  type: "video/mp4",
                  src:srcData
                  // src: require("../assets/Index/video1.mp4"), //视频url地址

                },

              ],

                poster: require("../assets/video_poster.png"), //你的封面地址

// width: document.documentElement.clientWidth,

                notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。

                controlBar: {
                timeDivider: true,
                  durationDisplay: true,
                  remainingTimeDisplay: false,
                  fullscreenToggle: false, //全屏按钮
              }
            }
          this.$set(this,'playerOptions',option)
            this.$refs.videoPlayer.player.play()
          }
        }).catch(error => {

        })
      },
      get_index_List:function(){
        index_List().then(resp=>{
          if(resp.status==200){
            this.$set(this, 'dataJson', resp.data)
          }
          let  language=localStorage.getItem('locale')
        }).catch(error=>{

        })
      },
      get_Home_News_list:function(){
        let data={
          page:this.currentPage,
          per_page:3,
        }
        Home_News_list(data).then(resp=>{
          if(resp.status==200){
            if(this.newList.length < Number(resp.data.meta.pagination.total)){
              resp.data.data.forEach(item=>{
                // item.create_time=this.formatDate(item.created_at)
                item.created_at = item.created_at.substring(0,10);
              })
              this.newList=[...this.newList,...resp.data.data]
              this.$set(this,'currentPage',this.currentPage++)
            } else {
              this.noData = true
            }
            this.$set(this,'total',resp.total)

          }
        }).catch(error=>{

        })
      },
      addCurrentPage:function(){
        // this.get_Home_News_list()
        this.$router.push({
          path:'/aboutUs',
          query:{
            url:'home'
          }
        })
      },
    }
  }
</script>

<style lang='scss' scoped>
  .vjs_video_3{
    height: 800px !important;
  }
  .home {
    .banner_1 {
      width: 100%;
      height: 800px;

      .swiper_content {
        width: 100%;
        height: 800px;
        .vjs-custom-skin{
          width: 100%;
          height: 800px;
        }
        .video-player{
          width: 100%;
          height: 800px;
          video{
            width: 100%;
            height: 800px;
          }
        }

        .dark_cover{
          position: fixed;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.6);
          z-index: -1;
        }

        .swiper-container {
          width: 100%;
          height: 800px;

          .swiper-wrapper {
            width: 100%;
            height: 800px;

            .swiper-slide {
              width: 100%;
              height: 800px;

              img {
                width: 100%;
                height: 800px;
              }
            }
          }

        }
      }
    }

    .banner_data {
      width: 100%;
      height: 220px;
      background-image: url("../assets/home/data-img.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: 67px;

      .banner_data_wrapper {
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-around;
        line-height: 220px;
        .banner-line{
          width: 2px;
          height: 125px;
          background: #D54724;
        }
        .banner_data_item {
          .banner_data_item_num {
            font-size: 60px;
            font-family: SourceHanSansCNVF-Regular;
            font-weight: 600;
            color: #D54724;
            line-height: 100px;
            position: relative;

            span {
              color: #4D4D4D;
              font-size: 44px;
              display: inline-block;
              line-height: 44px;
              position: absolute;
              right: -30px;
              top: 8px;
            }
          }

          .banner_data_item_name {
            font-size: 24px;
            font-family: SourceHanSansCNVF-Regular;
            font-weight: 400;
            color: #4D4D4D;
            line-height: 47px;
          }
        }
      }
    }

    .banner_introduction {
      box-sizing: border-box;
      margin-top: 90px;
      width: 100%;
      min-height: 590px;
      background-color: #F6F6F6;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      .box_introduction {
        width: 1205px;
        /*height: 490px;*/
        padding: 40px 0;
        //background-color: rgba(213, 71, 36, 0.1);
        //border-radius: 40px;
        box-sizing: border-box;
        //padding: 0px 40px 60px 40px;
        font-family: SourceHanSansCNVF-Regular;

        .box_introduction_title {
          margin-top: 50px;
          width: 100%;
          position: relative;
          font-family: SourceHanSansCNVF-Regular;
          text-align: center;


          .introduction_title {
            /*width: 120px;*/
            height: 25px;
            font-size: 26px;
            font-family: SourceHanSansCNVF-Regular;
            font-weight: 500;
            color: #333333;
            line-height: 50px;
            /*margin-left: calc(50% - 60px);*/
          }

          img {
            position: absolute;
            width: 890px;
            height: 240px;
            left: calc(50% - 455px);
            top: -70px;
          }
        }

        .introduction_content {
          margin-top: 60px;
          font-size: 18px;
          font-family: SourceHanSansCNVF-Regular;
          font-weight: 600;
          color: #4D4D4D;
          line-height: 28px;

          .paragraphs {
            text-indent: 32px;
            margin-bottom: 20px;
            font-size: 16px;
            font-family: SourceHanSansCNVF-Regular;
            font-weight: 400;
            color: #4D4D4D;
            line-height: 38px;
            /*word-wrap: break-word;white-space : normal;*/
            white-space: pre-wrap !important;
            word-wrap: break-word !important;
            *white-space: normal !important;
            text-align: justify;
          }
          pre{
            text-indent: 32px;
            white-space: pre-wrap;           /* css-3 */
            white-space: -moz-pre-wrap;      /* Mozilla, since 1999 */
            white-space: -pre-wrap;          /* Opera 4-6 */
            white-space: -o-pre-wrap;        /* Opera 7 */
            word-wrap: break-word;           /* Internet Explorer 5.5+ */
          }
        }

      }
    }
    .banner_live {
      width: 100%;
      /*height: 700px;*/
      box-sizing: border-box;
      //padding: 68px 0px 68px 68px;
      //margin-right: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .position-center-s{
        width: 1205px;
        display: flex;
        flex-flow: row nowrap;
      }
      .banner_live_letter {
        margin-top: 110px;
        width: 230px;
        height: 464px;
        font-size: 210px;
        font-family: SourceHanSansCNVF-Regular;
        font-weight: bold;
        color: rgba(213, 71, 36, 0.1);
        line-height: 210px;
        margin-right: 40px;
      }

      .live_introduction {
        width: 520px;
        margin-right: 77px;
        position: relative;
        margin-top: 170px;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        .live_introduction_dot {
          width: 126px;
          height: 94px;
          position: absolute;
          top: -190px;
          /*margin-bottom: 70px;*/

          img {
            width: 100%;
            height: 100%;
          }
        }

        .live_introduction_title {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          position: relative;
          .number-show-t{
            position: absolute;
            top: -90px;
            font-size: 110px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-style: italic;
            color: #D54724;
            opacity: 0.22;
          }

          .live_introduction_title_name {
            min-width: 110px;
            height: 60px;
            font-size: 50px;
            font-family: SourceHanSansCNVF-Regular;
            font-weight: 500;
            color: #333333;
            line-height: 50px;
            margin-right: 5px;
          }

          .live_introduction_title_logo {
            width: 46px;
            height: 46px;

            img {
              width: 46px;
              height: 46px;
            }
          }
        }

        .live_introduction_content {
          //margin-bottom: 20px;
          font-size: 16px;
          font-family: SourceHanSansCNVF-Regular;
          letter-spacing: 1px;
          font-weight: normal;
          color: #4D4D4D;
          line-height: 32px;
          text-align: justify;
        }

        .live_introduction_bg {
          width: 490px;
          height: 95px;
          margin: 20px 0px;

          img {
            width: 490px;
            height: 95px;
          }
        }
      }
    .live_swiper_shop{
      width: 640px;
      height: 426px;
      //border: 1px solid #D54724;
      border-radius: 40px;
      img{
        width: 640px;
        height: 426px;
        border-radius: 40px;
      }
    }
      .live_swiper {
        width: 640px;
        height: 426px;
        margin-top: 170px;
        //border: 1px solid #D54724;
        border-radius: 40px;
        position: relative;
        .swiper-button-prev{
          left: -60px;
        }
        .swiper-button-next{
          right: -60px;
        }
        .live_swiper_img{
          width: 640px;
          height: 426px;
          border-radius: 40px;
          img{
            width: 640px;
            height: 426px;
            border-radius: 40px;
          }
        }
        .swiper-container {
          width: 640px;
          height: 426px;
          border-radius: 40px;

          .swiper-wrapper {
            width: 640px;
            height: 426px;
            border-radius: 40px;

            .swiper-slide {
              border-radius: 40px;
              width: 640px;
              height: 426px;

              img {
                width: 640px;
                height: 426px;
                border-radius: 40px;
              }
            }
          }

        }
      }

      .game_swiper_img_wrapper{
        width: 640px;
        height: 426px;
        //border: 1px solid #D54724;
        border-radius: 40px;
        margin-right: 67px;
        img{
          width: 640px;
          height: 426px;
          border-radius: 40px;
        }
      }

      .game_swiper {
        width: 640px;
        height: 426px;
        //border: 1px solid #D54724;
        border-radius: 40px;
        margin-right: 67px;
        position: relative;
        .swiper-button-prev{
          left: -60px;
        }
        .swiper-button-next{
          right: -60px;
        }
        .swiper-container {
          width: 640px;
          height: 426px;
          border-radius: 40px;

          .swiper-wrapper {
            width: 640px;
            height: 426px;
            border-radius: 40px;

            .swiper-slide {
              border-radius: 40px;
              width: 640px;
              height: 426px;

              img {
                width: 640px;
                height: 426px;
                border-radius: 40px;
              }
            }
          }

        }
      }
    }

    .banner_MCN {
      padding-top: 68px!important;
      padding-bottom: 0px!important;
      box-sizing: border-box;
      padding-left: 0px !important;
      .mcn_swiper{
        width: 640px;
        height: 426px;
        //border: 1px solid #D54724;
        border-radius: 40px;
        margin-right: 57px;
        position: relative;
        margin-top: 65px!important;
      }
      .live_introduction{
        margin-top: 65px!important;
        margin-left: 43px!important;
        margin-right: 0 !important;
      }
      .mcn_pic {
        width: 640px;
        height: 426px;
        border-radius: 40px;
        margin-top: 65px!important;
        //border: 1px solid #D54724;
        //margin-right: 7px;
        img {
          width: 640px;
          height: 100%;
          border-radius: 40px;
        }
      }
      .swiper-button-prev{
        left: -60px;
      }
      .swiper-button-next{
        right: -60px;
      }
      .swiper-container {
        width: 640px;
        height: 426px;
        border-radius: 40px;
        //border: 1px solid #D54724;
        //margin-right: 67px;

        .swiper-wrapper {
          width: 640px;
          height: 426px;
          border-radius: 40px;

          .swiper-slide {
            width: 640px;
            height: 426px;
            border-radius: 40px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 40px;
            }
          }
        }

      }


      .live_introduction_dot {
        margin-left: 200px;
      }
    }

    .new {
      width: 100%;
      min-height: 674px;
      background-color: #F6F6F6;
      margin: 67px 0px;
      position: relative;
      .header-anchor {
        font-size: 26px;
        font-family: SourceHanSansSC;
        font-weight: 400;
        color: #333;
        position: relative;
        line-height: 30px;
        //margin: 30px 0 30px 0;
        text-align: center;
        padding-top: 67px;
      }
      .Semicircle_right {
        position: absolute;
        //z-index: -1;
        right: 0;
        top: -70px;
        width: 326px;
        height: 726px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .new_wrapper {
      width: 100%;
      min-height: 674px;
      background-color: transparent;
      z-index: 1;
      box-sizing: border-box;
      padding: 67px 357px 20px 357px;

      .new_content {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;

        .item_new {
          width: 340px;
          height: 450px;
          background-color: #fff;
          position: relative;
          //margin-left: 20px;
          //margin-right: 48px;
          //margin-right: 60px;
          margin-bottom: 40px;
          align-items: flex-start;
          cursor: pointer;
          border-radius: 30px 0px 30px 0px;
          a {
            text-decoration: none;
          }
          .imge_wrapper {
            box-sizing: border-box;
            width: 100%;
            height: 280px;
            border-top-left-radius: 30px;
            border-bottom-right-radius: 30px;
            //border: 1px solid #D54724;
            img {
              width: 100%;
              height: 280px;
              border-top-left-radius: 30px;
              border-bottom-right-radius: 30px;
              //border: 1px solid #D54724;
            }
          }

          .item_new_content {
            padding: 0px 22px 10px 15px;

            .item_new_title {
              font-size: 20px;
              font-family: SourceHanSansCNVF-Regular;
              font-weight: 500;
              color: #4D4D4D;
              line-height: 50px;
            }

            .item_new_text {
              font-size: 12px;
              font-family: SourceHanSansCNVF-Regular;
              font-weight: bold;
              color: #999999;
              line-height: 20px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
              overflow: hidden;
              text-align: justify;
            }


          }
          .new_time {
            position: absolute;
            left:15px;
            bottom: 5px;
            color: #999;
            margin-top: 10px;
            font-size: 12px;
          }

        }
        .item_new:hover{
          box-shadow: 0px 0px 8px 0px rgba(21,25,37,0.3500);
        }
      }
    }
  }

  .know_us {
    text-align: center;

    .know_more {
      background-color: #D54724;
      color: #fff;
      cursor: pointer;
    }
    .know_more_nodata{
      background-color: rgba(213,71, 36, 0.5);
      color: #fff;
      //cursor:not-allowed;
    }
  }

  .el-button {
    border: none;
    outline: none;
    border-radius: 50px;
  }

  .el-button:hover {
    background-color: rgba(213, 71, 36, 0.3) !important;
    color: #fff !important;
  }
  .banner_live {
    .el-divider--horizontal {
      width: 50px !important;
      margin: 40px 0 35px 0 !important;
      height: 3px;
      background-color: #D54724;
    }
  }

  .swiper .hide {
    opacity: 0;
  }

  .swiper-button-next, .swiper-button-prev {
    transition: opacity 0.5s;
  }

  .swiper-button-next:hover, .swiper-button-prev:hover{
    background-color: #DB6143;
    color: #fff;
  }

  .swiper-button-next, .swiper-button-prev {
    width: 46px;
    height: 46px;
    text-align: center;
    line-height: 46px;
    border-radius: 50%;
    color: #D54724;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(21,25,37,0.1700);
    //background-color: rgba(255, 255, 255, 0.3);
  }
  //.swiper-button-next {
  //  right: -60px;
  //  z-index: 999;
  //}
  //.swiper-button-prev{
  //  left: -60px;
  //  z-index: 999;
  //}
  .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
    font-size: 36px!important;
    //font-weight: bolder;
  }
  .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
    font-size: 36px!important;
    //font-weight: bolder;
  }
  .MCN_introduction_bg {
    width: 350px;
    height: 45px;
    margin-top: 20px;
    margin-bottom: 20px;

    img {
      width: 350px;
      height: 45px;
    }
  }

  .MCN_letter {
    margin-top: 112px !important;
    margin-right: 67px!important;
    height: 184px!important;
    transform: rotate(90deg) translateX(-90px) translateY(-90px);
    -ms-transform: rotate(90deg) translateX(-90px) translateY(-90px);
    -moz-transform: rotate(90deg) translateX(-90px) translateY(-90px);
    -webkit-transform: rotate(90deg) translateX(-90px) translateY(-90px);
    -o-transform: rotate(90deg) translateX(-90px) translateY(-90px);
    //transform: translate(60px);
  }

  .MCN_introduction_dot {
    position: absolute;
    /*margin-left: 394px !important;*/
    top: 398px!important;
    right: 0px!important;
  }

  .shop_logo {
    width: 334px !important;
    height: 95px !important;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .game_introduction_bg {
    width: 384px;
    height: 45px;
    margin: 20px 0px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .game {
    margin-top: 112px !important;
    margin-right: 67px!important;
    height: 184px!important;
    transform: translateY(-70px) !important;
    -ms-transform: translateY(-70px) !important;
    -moz-transform: translateY(-70px) !important;
    -webkit-transform: translateY(-70px) !important;
    -o-transform: translateY(-70px) !important;
  }

  .ad_pic {
    width: 904px;
    height: 388px;
    border-radius: 40px;
    margin-left: 67px;
    margin-top: 100px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 40px;
    }
  }
  .ad_swiper {
    width: 904px;
    height: 388px;
    //border: 1px solid #D54724;
    border-radius: 40px;
    margin-right: 67px;

    .swiper-container {
      width: 904px;
      height: 388px;
      border-radius: 40px;

      .swiper-wrapper {
        width: 904px;
        height: 388px;
        border-radius: 40px;

        .swiper-slide {
          border-radius: 40px;
          width: 904px;
          height: 388px;

          img {
            width: 904px;
            height: 388px;
            border-radius: 40px;
          }
        }
      }

    }
  }

  .ad_letter {
    margin-top: -70px !important;
  }

  .ad_introduction {
    width: 600px !important;
    margin-right: 40px !important;
  }

  .ad_logo {
    width: 390px !important;
    height: 95px !important;

    img {
      width: 390px !important;
      height: 95px !important;
    }
  }

  .wrapper {
    width: 100%;
    padding: 40px 0px;
  }

  .content_chart {
    width: 1200px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-left: calc(50% - 600px);
    border: 1px solid #D54724;
    border-radius: 30px;

    .chart_address {
      width: 310px;
      height: 100%;
    }

    .map-container {
      width: 890px;
      height: 560px;
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
      background-color: rgba(213, 71, 36, 0.1);
    }
  }
.game_banner{
  padding-top: 68px!important;
  padding-bottom: 0px!important;
  box-sizing: border-box;
  padding-left: 0px !important;
  margin-top: 100px;
  .game_swiper_img_wrapper{
    margin-top: 68px!important;
    margin-right: 0px!important;
  }
  .live_introduction{
    margin-top: 0px!important;
    margin-left: 43px!important;
    margin-right: 0px !important;
  }
}

  .ad_introduction_dot{
    position: absolute;
    top:422px!important;
  }
  @media all and (max-width: 792px) {
    .paragraphs {
      line-height: 70px !important;
    }
    .introduction_title{
      width: 280px  !important;
      margin-left: calc(50% - 140px) !important
    }
    .live_introduction_content{
      line-height: 55px !important;
      //display: -webkit-box;
      //-webkit-box-orient: vertical;
      //-webkit-line-clamp: 4;
      //overflow: hidden;
    }
    .btnWord {
      color: #D54724;
      cursor: pointer;
      word-break: keep-all;
    }
  }
</style>
